body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "inherit", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background: #555;
}

body::-webkit-scrollbar-thumb {
  background: #178f87;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #1cbfb4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

@media (max-width: 900px) {
  body {
    height: 100%;
  }
}

@media (min-width: 900px) {
  body {
    overflow-y: scroll;
  }
}
